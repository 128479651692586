/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}


@keyframes loading {
  0% {
      transform: rotate(0);
  }
  100% {
     transform: rotate(360deg);
  }
}

.sending-message-spinner {
  position: fixed;
  text-align: center;
  z-index: 20;
  display: block;
  width: 40px;
  height: 40px;
  border: 8px solid #B0B0B0;
  border-radius: 50%;
  border-top-color: #000;
  animation: loading .75s linear infinite;
}

modal-container+modal-container {
  background: rgba(0, 0, 0, 0.5);
}

a:not([href]) {
  color: #0366d6 !important;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

a:not([href]):hover {
  text-decoration: underline !important;
  color: #014c8c;
  transition: all 0.2s ease-in-out;
}
a:not([href]).disabled,
a:not([href]):disabled {
  &:hover {
    color: #007bff;
  }
}